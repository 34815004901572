import { ExternalLink, FooterMenu, FooterMenuCollection, Library, SanityKeyed, StaticPage } from "@libry-content/types";
import groq from "groq";
import { digitalLibraryFilter } from "../digitalLibrary/sanityQuery";

const resolveFooterMenu = groq`
  menuHeader,
  'menuItems': menuItems[]{
    _type == 'reference' => @-> { _id, title, _type },
    _type != 'reference' => @,
  }
`;

export const resolveHeaderFooterData = groq`{
  'libraries': *[_type == 'library' ]{
    _id,
    _type,
    slug
  },
  'footerMenuCollection': *[_type == 'footerMenuCollection'][0]{
    _id,
    _type,
    secondMenu {${resolveFooterMenu}},
    thirdMenu {${resolveFooterMenu}}
  },
  'bookListCount': count(*[_type == "list"]),
  'recommendationCount': count(*[_type == "recommendation"]),
  'servicesCount': count(*[_type == "service"]),
  'eventsCount': count(*[_type == "event"]),
  'employeeCount': count(*[_type == "employee" && showEmployee]),
  'digitalLibraryServiceCount': count(*[${digitalLibraryFilter}]),
  'accesibilityStatementUrl': accesibilityStatementUrl
}`;

export type HeaderFooterData = {
  footerMenuCollection: ResolvedFooterMenuCollection | null;
  libraries: Pick<Library, "_id" | "slug" | "_type">[];
  recommendationCount: number;
  servicesCount: number;
  eventsCount: number;
  bookListCount: number;
  employeeCount: number;
  digitalLibraryServiceCount: number;
  accesibilityStatementUrl: string;
};

export type FooterMenuItem = SanityKeyed<ExternalLink> | Pick<StaticPage, "_id" | "title" | "_type">;

export type ResolvedFooterMenu = Pick<FooterMenu, "menuHeader"> & {
  menuItems?: FooterMenuItem[];
};

export type ResolvedFooterMenuCollection = Pick<FooterMenuCollection, "_id" | "_type"> & {
  secondMenu?: ResolvedFooterMenu;
  thirdMenu?: ResolvedFooterMenu;
};
